const config = {
	headers: {
		Content_Type: "application/json",
	},
};

export const getConfig = (getState) => {
	let user = getState().auth.user;
	if (user.token) {
		config.headers["x-auth-token"] = user.token;
		return config;
	}
};

export let baseUrl;

if (process.env.NODE_ENV === "production") {
	baseUrl = "https://lmcs-2-0-api.onrender.com";
} else {
	baseUrl = "http://localhost:5000"; //"http://localhost:5000";
}

// http://localhost:5000
// https://stark-depths-01637.herokuapp.com
//https://lmcs-api.onrender.com/
