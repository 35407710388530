import React from "react";
import { Modal } from "react-bootstrap";

function CustomModal(props) {
	return (
		<Modal
			size={props.size}
			show={props.show}
			onHide={props.hide}
			aria-labelledby='example-modal-sizes-title-lg'>
			<Modal.Header closeButton>
				<Modal.Title
					id='example-modal-sizes-title-lg'
					style={{ textAlign: "center" }}>
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<div className='mx-auto'>{props.children}</div>
			</Modal.Body>
		</Modal>
	);
}

export default CustomModal;
