import React from "react";
import { Row } from "react-bootstrap";
import moment from "moment";

function Authority(props) {
  const { state } = props.location;
  const today = moment(state.createdAt).format("DD-MM-YYYY");
  return state && state ? (
    <div id="loan-contract">
      <div style={{ textAlign: "center" }}>
        <span style={{ textAlign: "left" }}>
          <p>Through The Bursar,</p>
          <p>University of Nigeria,Nsukka </p>
          <p>Enugu State Dear</p>

          <br />
          <br />
          <br />
          <p>Dear Sir/Madam,</p>
        </span>

        <h6>
          <span className="border border-success p-2 bg-info">
            AUTHORITY TO DEBIT MY SALARY ACCOUNT
          </span>
        </h6>

        <br />
      </div>

      <span style={{ textAlign: "left" }}>
        <Row className="row ml-1">
          <p>I {state?.loaneeDetails.name}</p>
          {"         "}
          <span className="ml-4">
            <p>
              <b>Staff Number:</b>{" "}
              {state?.loaneeDetails.employmentDetails.staffNum}
            </p>
          </span>
        </Row>

        <p>
          {" "}
          <b>IPPIS Number:</b> {state?.loaneeDetails.employmentDetails.ippisNum}
        </p>
        <p>
          <b>Faculty:</b> {state?.loaneeDetails.employmentDetails.faculty}
        </p>
        <p>
          <b>Department:</b> {state?.loaneeDetails.employmentDetails.department}{" "}
        </p>
        <p>
          <b>Account Name:</b> {state?.accountName}{" "}
        </p>
        <p>
          <b>Account Number:</b> {state?.accountNumber}{" "}
        </p>
        <p>
          <b>Bank Name:</b> {state?.bankName}{" "}
        </p>
        <br />

        <p>
          Authorize the Bursar, University of Nigeria, Nsukka to debit my salary
          account stated above with the loan amount owed and all expenses that
          the Co-operative may incur in the cause of prosecuting me should I
          fail to meet my obligation in paying the loan granted to me and remit
          all the monies to Lion Multi-Purpose Cooperative Society Limited.
        </p>

        <br />
        <p>This authority is irrevocable.</p>
        <br />
        <p>Yours Faithfully,</p>
        <br />
        <Row>
          <span style={{ width: "100px", height: "100px" }} className="col-4">
            <img
              src={state?.loaneeDetails.signature}
              alt={"..."}
              style={{ width: "100px", height: "60px", marginTop: "70px" }}
              className="mt-0"
            />
          </span>

          <p>Date:{today}</p>
        </Row>

        <p>
          Phone Number:{"   "}
          <b>{state.loaneeDetails.phone}</b>
        </p>
      </span>
    </div>
  ) : (
    <>
      <p>loading...</p>
    </>
  );
}

export default Authority;
