import React from "react";
import KanbanBoardContainer from "../utilities/KanbanBoardContainer";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { Button } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { fetchLoanRequest } from "../../store/actions/adminActions";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import format from "format-number";

function LoanSummary() {
  const { loanRequests } = useSelector((state) => state.admin);
  const dispatch = useDispatch();
  let data = [];

  React.useEffect(() => {
    dispatch(fetchLoanRequest());
  }, [dispatch]);

  const acknowledgedLoans = loanRequests?.filter((member) => {
    return member?.acknowledged === "seen";
  });

  let flattenObj = (obj) => {
    let result = {};
    for (const i in obj) {
      if (typeof obj[i] === "object" && !Array.isArray(obj[i])) {
        const temp = flattenObj(obj[i]);
        for (const j in temp) {
          result[j] = temp[j];
        }
      } else {
        result[i] = obj[i];
      }
    }
    return result;
  };

  acknowledgedLoans.forEach((loan, index) => {
    loan.deductedInterest = format({ prefix: "₦" })(
      (loan.proRata * loan.amount) / 100
    );
    loan.proRata = format({ suffix: "%" })(loan.proRata);
    loan.serial = index + 1;

    loan.handlingCharge = format({ prefix: "₦" })(loan.amount * 0.005);
    loan.payable = format({ prefix: "₦" })(loan.payable);
    loan.time = moment(loan.requestedAt).format(
      "dddd, MMMM Do YYYY, h:mm:ss a"
    );

    data.push(flattenObj(loan));
  });

  const columns = [
    {
      dataField: "serial",
      text: "#",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "50px",
      },
    },
    {
      dataField: "name",
      text: "Name",

      sort: true,
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "ippisNum",
      text: "IPPIS number",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "staffNum",
      text: "Staff Number",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "memberId",
      text: "Member Id",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "amount",
      text: "Loan Amount(₦)",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "loanType",
      text: "Loan Type",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "duration",
      text: "Duration (months)",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "interestRate",
      text: "Interest rate(%)",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "proRata",
      text: "Pro-rated Percentage Interest(%)",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "deductedInterest",
      text: "Interest Deducted at source",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "handlingCharge",
      text: "Handling charge deducted at source",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
    {
      dataField: "payable",
      text: "Amount Payable Per month",
      headerStyle: {
        backgroundColor: "#c8e6c9",
        width: "200px",
      },
    },
  ];

  const MyExportCSV = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    return (
      <div style={{ textAlign: "right" }}>
        <Button variant="primary" size="lg" onClick={handleClick}>
          Export To CSV
        </Button>
      </div>
    );
  };
  return (
    <KanbanBoardContainer>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">Loan Requests</h3>
        </div>
        <section className="content pb-3">
          <div className="card-body">
            <ToolkitProvider
              stripped
              hover
              keyField="_id"
              columns={columns}
              data={data}
              exportCSV
            >
              {(props) => (
                <div>
                  <MyExportCSV {...props.csvProps} />
                  <hr />
                  <BootstrapTable {...props.baseProps} />
                </div>
              )}
            </ToolkitProvider>
          </div>
        </section>
      </div>
    </KanbanBoardContainer>
  );
}

export default LoanSummary;
